.Error {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;

  &__logo {
    height: auto;
    max-width: 250px;
  }

  &__stack {
    padding: 0 10px;
    max-width: 100vw;
  }

  pre {
    text-align: left;
    font-size: 11px;
    background: black;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    overflow: auto;
  }
}
