@import '@beatport/ui/scss/themes/dark.scss';
@import '@beatport/ui/scss/partials/base.scss';
@import '@beatport/ui/scss/partials/mixins.scss';
@import '@beatport/ui/scss/partials/animations.scss';
@import '@beatport/ui/components/Buttons/Buttons.scss';
@import '@beatport/ui/components/Loader/Loader.scss';
@import '@beatport/ui/components/Player/Fader.scss';
@import '@beatport/ui/components/Player/Player.scss';
@import '@beatport/ui/components/Waveform/Waveform.scss';
@import '@beatport/ui/components/Waveform/Realtime.scss';
@import '@beatport/ui/components/Tracks/Tracks.scss';

.Player {
  &__container {
    @media screen and (min-width: 568px) {
      flex-wrap: wrap !important;
    }
  }

  &__artwork-2 {
    position: relative;
    left: auto;
    margin-right: 5px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__track-info {
    order: 1;

    @media screen and (max-width: 568px) {
      flex: 0 1 300px;
    }

    [class^=track-] {
      color: #fff;
    }
  }

  &__track-info-2 {
    order: 2;
  }

  &__waveform {
    order: 3;
  }

  &__extra {
    order: 4;
    flex: 1 1 50px;
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 568px) {
      flex: 0 1 50px;
      width: 100% !important;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    @media screen and (min-width: 1025px) {
      justify-content: center;
    }
  }

  &__volume {
    display: none;
  }

  &__controls {
    order: 0;
    margin-left: 5px;
    margin-right: 5px;
  }

  &__controls &__button--small {
    display: none;
  }

  &__button {
    background: none;
    width: 50px;
    height: 50px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.track {
  font-weight: normal;
  background: none;
  font-size: 12px;
}

.track:hover .track__play-icon {
  display: block;

  path {
    fill: white;
  }
}

.track:hover .track__artwork {
  background-color: rgba(40, 54, 70, 1);
}

.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  &__header {
    background: black;
    padding: 2px 10px 0;
    width: 100%;
  }

  &__header-logo {
    width: auto;
    height: 30px;
    display: block;
  }

  &__player-container {
    width: 100%;
  }

  &__info-container {
    width: 100%;
    flex: 1;
  }
}

.ActionButton {
  border-radius: 50%;
  width: 20px;
  height: 20px;

  &--buy {
    margin: 0 5px 0 0;

    @media screen and (min-width: 300px) {
      margin: 0 10px 0 0;
    }
  }

  &--share {
    margin: 0;
  }

  @media screen and (min-width: 300px) {
    width: 40px;
    height: 40px;
  }

  &__text {
    display: none;
  }

  &__icon {
    width: 10px !important;
    left: -1px;
    height: auto !important;
    position: relative;

    @media screen and (min-width: 300px) {
      width: 15px !important;
      left: -1px;
    }
  }
}

.Share {
  margin-top: 50px;
}

.ShareButton {
  &__icon {
    margin-right: 10px !important;
  }
}
