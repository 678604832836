body {
  margin: 0;
  padding: 0;
  background: #000;
  color: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
